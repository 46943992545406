import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import {useState} from "react";
import {ToastContainer} from 'react-toastify';
import {IntlProvider} from "react-intl";
import LanguageContext from "./contexts/LanguageContext";
import {ro} from "./translations/ro/ro"
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Login from "./screens/Auth/Login/Login";
import Account from "./screens/Owner/Settings/Account/Account";
import Company from "./screens/Owner/Settings/Company/Company";
import moment from "moment";
import 'moment/locale/ro';
import 'moment/locale/en-in';
import * as localeRo from 'moment/locale/ro';
import * as localeEnIn from 'moment/locale/en-in';
import Modal from "react-modal";
import Users from "./screens/Owner/Settings/Users/Users";
import AddUser from "./screens/Owner/Settings/Users/AddUser";
import EditUser from "./screens/Owner/Settings/Users/EditUser";
import {registerLocale} from "react-datepicker";
import rom from "date-fns/locale/ro";
import * as eng from "date-fns/locale/en-IE";
import Vats from "./screens/Owner/Settings/Company/Vats/Vats";
import AddVat from "./screens/Owner/Settings/Company/Vats/AddVat";
import EditVat from "./screens/Owner/Settings/Company/Vats/EditVat";
import Register from "./screens/Auth/Register/Register";
import VerifyEmail from "./screens/Auth/Register/VerifyEmail";
import Companies from "./screens/Admin/Companies/Companies";
import CompanyInfo from "./screens/Admin/Companies/CompanyInfo/CompanyInfo";
import UsersForCompany from "./screens/Admin/Companies/CompanyInfo/Users";
import ForgotPassword from "./screens/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/Auth/ForgotPassword/ResetPassword";
import axios from "axios";
import {isJsonString} from "./utils/functions";
import Series from "./screens/Owner/Settings/Company/DocumentsSeries/Series";
import AddSeries from "./screens/Owner/Settings/Company/DocumentsSeries/AddSeries";
import EditSeries from "./screens/Owner/Settings/Company/DocumentsSeries/EditSeries";
import CompanyMenu from "./screens/Owner/Settings/Company/CompanyMenu";
import AdminUsers from "./screens/Admin/Users/Users";
import Subscription from "./screens/Admin/Companies/CompanyInfo/Subscription";
import SubscriptionExpired from "./screens/Owner/SubscriptionExpired/SubscriptionExpired";
import Clients from "./screens/Owner/Clients/Clients";
import AddClient from "./screens/Owner/Clients/AddClient";
import EditClient from "./screens/Owner/Clients/EditClient/EditClient";
import InvoicesMenu from "./screens/Owner/Invoices/InvoicesMenu";
import InvoicesList from "./screens/Owner/Invoices/Invoices";
import ContractsList from "./screens/Owner/Contracts/ContractsList";
import DocumentTemplates from "./screens/Owner/DocumentTemplates/DocumentTemplates";
import AddTemplate from "./screens/Owner/DocumentTemplates/AddTemplate";
import EditTemplate from "./screens/Owner/DocumentTemplates/EditTemplate";
import ReportBugs from "./screens/Owner/ReportBugs/ReportBugs";
import "react-datepicker/dist/react-datepicker.css";
import EditClientMenu from "./screens/Owner/Clients/EditClient/EditClientMenu";
import Places from "./screens/Owner/Clients/EditClient/Places/Places";
import AddPlace from "./screens/Owner/Clients/EditClient/Places/AddPlace";
import EditPlace from "./screens/Owner/Clients/EditClient/Places/EditPlace";
import Requests from "./screens/Owner/ProjectRequests/Requests";
import AddRequest from "./screens/Owner/ProjectRequests/AddRequest";
import EditRequestMenu from "./screens/Owner/ProjectRequests/EditRequest/EditRequestMenu";
import EditRequest from "./screens/Owner/ProjectRequests/EditRequest/EditRequest";
import UploadDocuments from "./screens/Owner/ProjectRequests/EditRequest/UploadDocuments";
import ClientRequest from "./screens/Client/Request/ClientRequest";
import RequestLogs from "./screens/Owner/ProjectRequests/EditRequest/RequestLogs";
import RequestStatusCheck from "./screens/Client/RequestStatusCheck";
import DocumentsVerification
    from "./screens/Owner/ProjectRequests/EditRequest/DocumentsVerification";
import EditOffer from "./screens/Owner/ProjectRequests/EditRequest/Offer/EditOffer";
import Products from "./screens/Owner/Products/Products";
import AddProduct from "./screens/Owner/Products/AddProduct";
import EditProduct from "./screens/Owner/Products/EditProduct";
import EditOfferFile from "./screens/Owner/ProjectRequests/EditRequest/Offer/EditOfferFile";
import Offer from "./screens/Owner/ProjectRequests/EditRequest/Offer/Offer";
import ViewOffer from "./screens/Owner/ProjectRequests/EditRequest/Offer/ViewOffer";
import Contract from "./screens/Owner/ProjectRequests/EditRequest/Contract/Contract";
import EditContractFile
    from "./screens/Owner/ProjectRequests/EditRequest/Contract/EditContractFile";
import ViewContract from "./screens/Owner/ProjectRequests/EditRequest/Contract/ViewContract";
import EditContract from "./screens/Owner/ProjectRequests/EditRequest/Contract/EditContract";
import Efactura from "./screens/Owner/Settings/Company/Efactura/Efactura";
import EfacturaInvoices from "./screens/Owner/Invoices/EfacturaInvoices";
import OffersList from "./screens/Owner/Offers/OffersList";
import EmailMailjet from "./screens/Admin/Companies/CompanyInfo/EmailMailjet";
import Projects from "./screens/Owner/Projects/Projects";
import Annexes from "./screens/Owner/ProjectRequests/EditRequest/Contract/Annexes";
import AddAnnex from "./screens/Owner/ProjectRequests/EditRequest/Contract/AddAnnex";
import EditAnnex from "./screens/Owner/ProjectRequests/EditRequest/Contract/EditAnnex";

Modal.setAppElement('body');
export default function App() {

    const isJwt = localStorage.getItem('jwt') !== null
    const role = localStorage.getItem('role') ?? null
    const access = JSON.parse(localStorage.getItem('access') ?? '{}')
    const subscription_expiration_date = localStorage.getItem('subscription_expiration_date')

    const [language, setLanguage] = useState('ro')

    const getLanguageFile = (lang) => {
        switch (lang) {
            case 'ro':
                moment.updateLocale('ro', localeRo);
                registerLocale('ro', rom);
                moment.updateLocale('ro', {
                    week: {
                        dow: 1, // Monday is the first day of the week.
                    }
                });
                return ro
            case 'en':
                moment.updateLocale('en', localeEnIn);
                registerLocale('en', eng);
                return ro
            default:
                return ro
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const languageContextValue = {
        language: language,
        setLanguage: changeLanguage
    };

    [window, document].forEach(
        el => el.addEventListener('message', (message) => {

            let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false
            if (data) {
                // eslint-disable-next-line
                switch (data.action) {
                    case 'registerPushToken':
                        registerPushToken(data.token)
                        break
                }
            }
        }))

    const registerPushToken = async (token) => {
        let data = {
            token: token
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_API + '/users/updatePushToken', data, auth)
    }

    return (
        <LanguageContext.Provider value={languageContextValue}>
            <IntlProvider messages={getLanguageFile(language)} locale={language}>
                <ToastContainer/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/request/:code" element={<ClientRequest/>}/>
                        <Route path="/status-check" element={<RequestStatusCheck/>}/>
                        {
                            !isJwt || !role?
                                <>
                                    <Route exact path="/" element={<Login/>}/>
                                    <Route exact path="/register" element={<Register/>}/>
                                    <Route exact path="/verify-email/:id" element={<VerifyEmail/>}/>
                                    <Route exact path="/forgot-password"
                                           element={<ForgotPassword/>}/>
                                    <Route exact path="/reset-password" element={<ResetPassword/>}/>
                                </>
                                :
                                role && parseInt(role) === 4 ? // Role: Admin

                                            <Route element={<Layout/>}>
                                                <Route exact path="/company"
                                                       element={<Companies/>}/>
                                                <Route exact path="/users" element={<AdminUsers/>}/>
                                                <Route path="/company/:id" element={<CompanyInfo/>}>
                                                    <Route exact path={"subscription"}
                                                           element={<Subscription/>}/>

                                                    <Route exact path="email-mailjet"
                                                           element={<EmailMailjet/>}/>
                                                    <Route exact path={"users"}
                                                           element={<UsersForCompany/>}/>
                                                </Route>
                                                <Route exact path={""} element={<Account/>}/>
                                            </Route>
                                    :
                                    role && parseInt(role) === 1 && // Role: Owner
                                    <>
                                        {
                                            moment(subscription_expiration_date).unix() < moment().unix() ?
                                                <>
                                                    <Route exact path="/"
                                                           element={<SubscriptionExpired/>}/>
                                                    <Route exact path="/subscription-expired"
                                                           element={<SubscriptionExpired/>}/>
                                                </>
                                                :
                                                <Route element={<Layout/>}>

                                                    {access && (access.clients) &&
                                                        <>
                                                            <Route path="/clients"
                                                                   element={<Clients/>}/>
                                                            <Route exact path={"/clients/add"}
                                                                   element={<AddClient/>}/>

                                                            <Route path="/clients/:id"
                                                                   element={<EditClientMenu/>}>
                                                                <Route exact path={"edit"}
                                                                       element={<EditClient/>}/>
                                                                <Route exact path={"places"}
                                                                       element={<Places/>}/>
                                                                <Route exact path={"places/add"}
                                                                       element={<AddPlace/>}/>
                                                                <Route exact
                                                                       path={"places/:place_id"}
                                                                       element={<EditPlace/>}/>
                                                            </Route>
                                                        </>
                                                    }
                                                    {access && (access.products) &&
                                                        <>
                                                            <Route path="/products"
                                                                   element={<Products/>}/>
                                                            <Route exact path={"/products/add"}
                                                                   element={<AddProduct/>}/>
                                                            <Route exact path={"/products/:id"}
                                                                   element={<EditProduct/>}/>
                                                        </>}
                                                    {access && (access.requests) && <>

                                                        <Route exact path={"/requests"}
                                                               element={<Requests/>}/>
                                                        <Route exact path={"/requests/add"}
                                                               element={<AddRequest/>}/>

                                                        <Route path="/requests/:id"
                                                               element={<EditRequestMenu/>}>
                                                            <Route exact path={"edit"}
                                                                   element={<EditRequest/>}/>
                                                            <Route exact path={"documents"}
                                                                   element={<UploadDocuments/>}/>
                                                            <Route exact path={"actions"}
                                                                   element={<RequestLogs/>}/>
                                                            <Route exact path={"documents-verification"}
                                                                   element={<DocumentsVerification/>}/>
                                                            <Route exact path={"offer"}
                                                                   element={<Offer/>}/>
                                                            <Route exact path={"offer/generate"}
                                                                   element={<EditOffer/>}/>
                                                            <Route exact path={"offer/file"}
                                                                   element={<EditOfferFile/>}/>
                                                            <Route exact path={"offer/view"}
                                                                   element={<ViewOffer/>}/>
                                                            <Route exact path={"contract"}
                                                                   element={<Contract/>}/>
                                                            <Route exact path={"contract/generate"}
                                                                   element={<EditContract/>}/>
                                                            <Route exact path={"contract/file"}
                                                                   element={<EditContractFile/>}/>
                                                            <Route exact path={"contract/view"}
                                                                   element={<ViewContract/>}/>
                                                            <Route exact path={"contract/:contract/annexes"}
                                                                   element={<Annexes/>}/>
                                                            <Route exact path={"contract/:contract/annexes/add"}
                                                                   element={<AddAnnex/>}/>
                                                            <Route exact path={"contract/:contract/annexes/:annex"}
                                                                   element={<EditAnnex/>}/>
                                                        </Route>

                                                    </>}

                                                    {access && (access.projects) && <>

                                                    <Route exact path={"/projects"}
                                                           element={<Projects/>}/>
                                                    </>}

                                                    <Route exact path={"/templates"}
                                                           element={<DocumentTemplates/>}/>
                                                    <Route exact path={"/templates/add"}
                                                           element={<AddTemplate/>}/>
                                                    <Route exact path={"/templates/:id"}
                                                           element={<EditTemplate/>}/>
                                                    {access && (access.invoices) &&
                                                        <Route path="/invoices"
                                                               element={<InvoicesMenu/>}>
                                                            <Route path="efactura" element={<EfacturaInvoices/>}/>
                                                            <Route path=""
                                                                   element={<InvoicesList/>}/>
                                                        </Route>}

                                                    {access && (access.contracts) &&
                                                        <Route path="/contracts"
                                                               element={<ContractsList/>}/>}
                                                    {access && (access.offers) &&
                                                        <Route path="/offers"
                                                               element={<OffersList/>}/>}


                                                    <Route exact path={"/"}
                                                           element={<Account/>}/>

                                                    {access && (access.company) &&
                                                        <Route path="/company"
                                                               element={<CompanyMenu/>}>
                                                            <Route exact path={"edit"}
                                                                   element={<Company/>}/>

                                                            <Route exact path={"e-factura"} element={<Efactura/>}/>
                                                            <Route exact path={"vats"}
                                                                   element={<Vats/>}/>
                                                            <Route exact path={"vats/add"}
                                                                   element={<AddVat/>}/>
                                                            <Route exact path={"vats/:id"}
                                                                   element={<EditVat/>}/>
                                                            <Route exact
                                                                   path={"documents-series"}
                                                                   element={<Series/>}/>
                                                            <Route exact
                                                                   path={"documents-series/add"}
                                                                   element={<AddSeries/>}/>
                                                            <Route exact
                                                                   path={"documents-series/:id"}
                                                                   element={<EditSeries/>}/>
                                                        </Route>}

                                                    {access && (access.users) &&
                                                        <Route exact path={"/users"}
                                                               element={<Users/>}/>}
                                                    {access && (access.users) &&
                                                        <Route exact path={"/users/add"}
                                                               element={<AddUser/>}/>}
                                                    {access && (access.users) &&
                                                        <Route exact path={"/users/:id"}
                                                               element={<EditUser/>}/>}

                                                    <Route exact path={"bugs"}
                                                           element={<ReportBugs/>}/>
                                                </Route>
                                        }
                                    </>
                        }
                    </Routes>
                </BrowserRouter>
            </IntlProvider>
        </LanguageContext.Provider>
    )
}


