import React, {useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import Button from "../elements/Button/Button";
import moment from "moment";

export default function ContractsTable({
                                           contracts,
                                           selectedContracts,
                                           setSelectedContracts,
                                       }) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [loadingDownload, setLoadingDownload] = useState([])

    const download = async (contract) => {
        let load = [];
        load[contract.id] = true;
        setLoadingDownload(load)
        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API +  '/contracts/download-for-request/' + contract.project_request.id,
            }))

        } else {
            axios.get(process.env.REACT_APP_API + '/contracts/download-for-request/' + contract.project_request.id, {
                responseType: 'blob',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'contract-' + contract.series + contract.number + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
        setLoadingDownload([])
    }
    const toggleSelectContract = (contractId) => {

        if (selectedContracts.includes(contractId)) {
            setSelectedContracts(selectedContracts.filter((item) => item !== contractId))
        } else {
            setSelectedContracts([...selectedContracts, contractId])
        }

    }

    return (
        <div className={"content p-0"}>
            <div className={"table contracts mb-1"}>
                <div className={"table-head"}>
                    <div className={"row"}>
                        <div
                            className={"col-1 table-td"}>
                            <input type={"checkbox"}
                                   onChange={() => {
                                       if (selectedContracts.length === contracts.length) {
                                           setSelectedContracts([])
                                       } else {
                                           setSelectedContracts(contracts.map((item) => item.id))
                                       }
                                   }}
                                   value={'all'}
                                   checked={selectedContracts.length > 0 && selectedContracts.length === contracts.length}
                            /></div>
                        <div
                            className={"table-td col-2"}>{intl.formatMessage({id: 'general.date'})}</div>
                        <div className={"table-td col-3"}>{intl.formatMessage({id: 'general.client'})}</div>
                        <div
                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.send_to_client'})}</div>
                        <div
                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.signed_by_client'})}</div>
                        <div className={"table-td col-2"}></div>
                    </div>
                </div>


                <div className={"table-body"}>
                    {
                        contracts.map((contract, i) => (
                            <div className={"table-row"} key={i}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>
                                        <input type={"checkbox"} className={"me-2"}
                                               onChange={() => toggleSelectContract(contract.id)}
                                               value={contract.id}
                                               checked={selectedContracts.includes(contract.id)}/>

                                        {contract.series}{contract.number}
                                    </div>
                                    <div
                                        className={"table-td col-2"}>
                                        {moment(contract.date).format('DD/MM/YY')}
                                    </div>
                                    <div className={"table-td col-3"}>{contract.project_request.client.type === 'individual' ?
                                        (contract.project_request.client.first_name +' '+ contract.project_request.client.last_name)
                                        : contract.project_request.client.company_title}</div>
                                    <div
                                        className={"col-2 table-td"}>
                                        <i className={contract.sent_to_client ? "fa-solid fa-check-double" : "fa-solid fa-xmark" }
                                           style={{color: contract.sent_to_client ? "green" : "red", margin: "auto"}}/>
                                    </div>
                                    <div
                                        className={"col-2 table-td"}>

                                        <i className={contract.signed_by_client ? "fa-solid fa-check-double" : "fa-solid fa-xmark" }
                                           style={{color: contract.signed_by_client ? "green" : "red", margin: "auto"}}/>
                                    </div>
                                    <div className={"table-td col-2"}>
                                        <Button
                                            className={"btn-light btn-edit ms-auto"}
                                            icon={"fa-solid fa-download"}
                                            whiteLoader={false}
                                            loading={loadingDownload[contract.id] ?? false}
                                            disabled={loadingDownload[contract.id] ?? false}
                                            tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                            onClick={() => {
                                                    download(contract)
                                            }}
                                        />
                                       <Button
                                            className={"btn-light btn-edit ms-2 "}
                                            icon={"fa-solid fa-bell"}
                                            tooltip={intl.formatMessage({id: 'contracts.view_request'})}
                                            onClick={() => navigate('/requests/'+contract.project_request.id+'/contract')}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );
}

