import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {downloadEfactura, downloadXml} from "../../../utils/functions";
import Paginate from "../../../components/elements/Paginate/Paginate";

export default function EfacturaInvoices() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState('')
    const [messages, setMessages] = useState([])
    const [allMessages, setAllMessages] = useState([])
    const [page, setPage] = useState(1)
    const [loadingDownload, setLoadingDownload] = useState([])
    const [loadingDownloadXml, setLoadingDownloadXml] = useState([])


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setMessages(allMessages.slice((page - 1) *10, page * 10))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/efactura/list', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                 let temp = []
                if (response.data.mesaje) {
                    temp = response.data.mesaje.sort((a,b) => a.data_creare > b.data_creare ? -1 : 1)
                    setAllMessages(temp)
                    setMessages(temp.slice((page - 1) *10, page * 10))
                }
                if (response.data.titlu) {
                    setTitle(response.data.titlu)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{title}</p>
                    </div>

                </div>
            </div>

            {
                messages.length === 0 ?
                    <div className={"content"}>

                        <div
                            className={"alert alert-warning"}>{intl.formatMessage({id: 'invoices.alert_no_invoices_found'})}</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>
                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-2 table-td"}>
                                        {intl.formatMessage({id: 'invoices.upload_id'})}
                                    </div>

                               {/*     <div
                                        className={"col-2 table-td"}>
                                        {intl.formatMessage({id: 'invoices.download_id'})}
                                    </div>*/}
                                    <div
                                        className={"table-td col-2"}>{intl.formatMessage({id: 'general.type'})}</div>
                                    <div
                                        className={"table-td col-2"}>{intl.formatMessage({id: 'events.invoices.date'})}</div>
                                    <div className={"table-td col-4"}>{intl.formatMessage({id: 'invoices.details'})}</div>
                                    <div className={"table-td col-2"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    messages.map((message, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {message.id_solicitare}
                                                </div>
                                              {/*  <div
                                                    className={"col-2 table-td"}>
                                                    {message.id}
                                                </div>*/}
                                                <div
                                                    className={"table-td col-2"}>
                                                    {message.tip}
                                                </div>

                                                <div
                                                    className={"table-td col-2"}>

                                                    {message.data_creare.slice(6,8)}/{message.data_creare.slice(4,6)}/{message.data_creare.slice(0,4)}
                                                    {' ' + message.data_creare.slice(8,10)}:{message.data_creare.slice(10,12)}
                                                </div>
                                                <div
                                                    className={"table-td col-4"}>
                                                    {message.detalii}
                                                </div>
                                                <div className={"table-td col-2"}>
                                                    {
                                                        (message.tip === 'FACTURA TRIMISA' || message.tip === 'FACTURA PRIMITA' )&&
                                                        <Button
                                                            className={"btn-light btn-edit ms-2"}
                                                            icon={"fa-solid fa-file-pdf"}
                                                            tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                            whiteLoader={false}
                                                            loading={loadingDownload[i] ?? false}
                                                            disabled={loadingDownload[i] ?? false}
                                                            onClick={() => {
                                                                let temp = []
                                                                temp[i] = true
                                                                setLoadingDownload(temp)
                                                               downloadEfactura(message.id,message.id_solicitare, intl, jwt,setLoadingDownload)
                                                            }}
                                                        />
                                                    }
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-file-excel"}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                        whiteLoader={false}
                                                        loading={loadingDownloadXml[i] ?? false}
                                                        disabled={loadingDownloadXml[i] ?? false}
                                                        onClick={() => {
                                                            let temp = []
                                                            temp[i] = true
                                                            setLoadingDownloadXml(temp)
                                                            downloadXml(message.id,message.id_solicitare, intl, jwt, setLoadingDownloadXml)

                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>


                    </div>

            } <div className={"footer d-flex justify-content-center"}>
            <Paginate
                page={page}
                setPage={setPage}
                totalItems={allMessages.length}
            />
        </div>
        </div>
    );
}

