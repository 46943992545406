import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function Projects() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [projects, setProjects] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/projects/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setProjects(response.data.projects ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.projects'})}</h1>
                </div>

                <div className={"col-md-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'general.sidebar.projects'})}</p>
                        </div>


                        {
                            projects.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'general.alert_no_projects'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.type'})}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                projects.map((project, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {i + 1 + (page - 1) * 10}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>

                                                                {intl.formatMessage({id: 'requests.type.' + project.request.type})}
                                                            </div>
                                                            <div
                                                                className={"col-4 table-td"}>
                                                                {project.request.client.type === 'individual' ? (project.request.client.first_name + ' ' + project.request.client.last_name)
                                                                    : project.request.client.company_title}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/projects/' + project.id + '/edit')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

