import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {units} from "../../../utils/constants";

export default function AddProductForm({id, showBackButton, isOnSave = false, onSave = () => {}}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [vat, setVat] = useState(null)
    const [price, setPrice] = useState(0)
    const [unit, setUnit] = useState('')
    const [unitsOptions, setUnitsOptions] = useState([])
    const [items, setItems] = useState([])
    const [vats, setVats] = useState([])
    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()
    useEffect(() => {
        let temp = []
        units.forEach((item) => {
            temp.push({
                value: item,
                label: intl.formatMessage({id: 'general.units.' + item})
            })
        })
        setUnitsOptions(temp)
        if (id !== 0) {
            getData()
        }else{

            getVats()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {

        await axios.get(process.env.REACT_APP_API + '/products/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response && response.data) {
                    setPrice(response.data.price)
                    setItems(response.data.items)
                    setTitle(response.data.title)
                    setUnit(response.data.unit)
                    setVat(response.data.vat)
                    getVats()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

    }
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.value,
                    })
                })
                setVats(options)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        if (price === 0 || vat === null || title === '' || unit === '') {
            toast.error(intl.formatMessage({id: 'errors.please_fill_all_fields'}))
        } else {
            setSaveLoading(true)
            await axios.post(process.env.REACT_APP_API + '/products', {
                title: title,
                vat: vats.find(item => item.value === vat).label,
                unit,
                items,
                price
            }, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
                .then(async (response) => {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (isOnSave){
                        onSave(response.data.product)
                    }else {
                        context.setContentUpdated(false)
                        navigate(-1)
                    }
                    setSaveLoading(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                })
            setSaveLoading(false)
        }

    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.title'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"} required
                                   value={title} onChange={(e) => {
                                context.setContentUpdated(true)
                                setTitle(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"number"} className={"form-input"} required
                                   value={price} onChange={(e) => {
                                context.setContentUpdated(true)
                                setPrice(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.vat'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={vats}
                                required
                                value={vat}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setVat(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.unit'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={unitsOptions}
                                required
                                value={unit}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setUnit(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>

                    <div className={"col-12"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.products.included_items'})}</div>
                    </div>
                    {
                        items.map((item, i) => (
                            <div className={"col-12"}>
                                <div className={"price-input-container"}>
                                    <p><i className="fa-solid fa-trash-alt"
                                          style={{cursor: "pointer", color: "red"}} onClick={() => {
                                        let temp = [...items]
                                        temp.splice(i, 1)
                                        setItems(temp)
                                    }}></i>
                                    </p>
                                    <input type={"text"} className={"form-input mb-3"} required
                                           placeholder={intl.formatMessage({id: 'general.title'})}
                                           value={item.title} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        let temp = [...items]
                                        temp[i].title = e.target.value
                                        setItems(temp)
                                    }}/>
                                </div>

                            </div>
                        ))
                    }
                    <div className={"col-12"}>

                        <Button
                            className={"btn-secondary mt-2 w-100"}
                            icon={"fa-solid fa-plus"}
                            style={{width: '100%'}}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => {
                                let temp = [...items]
                                temp.push({
                                    title: ''
                                })
                                setItems(temp)
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        {showBackButton && <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {
                                context.setLeavePage(true)
                                context.setNextLink('/products')
                            }}
                        />}
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        <Button
                            className={"btn-primary mt-2 ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={'submit'}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}

