import {useIntl} from "react-intl";
import React from "react";
import {useParams} from "react-router-dom";
import AddProductForm from "./AddProductForm";

export default function EditProduct() {

    const intl = useIntl();
    let params = useParams();


    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.products'})}</h1>
                </div>

                <div className={"col-md-12"}>
                    <div className="card">
                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'general.edit_product'})}</p></div>
                        <AddProductForm id={params.id} showBackButton={true} isOnSave={true}
                                        onSave={() => {
                                        }}/>
                    </div>
                </div>
            </div>
        </div>

    );
}

